@font-face {
    font-family: 'Everett';
    src: url('/public/fonts/Everett-Light.woff2') format('woff2'), url('/public/fonts/Everett-Light.woff') format('woff');
    font-display: fallback;
}

@font-face {
    font-family: 'Canela';
    src: url('/public/fonts/Canela-Light.woff2') format('woff2'), url('/public/fonts/Canela-Light.woff') format('woff');
    font-display: fallback;
}


.canela {
    font-family: 'canela';
}

.everett {
    font-family: 'everett';
}