@import 'breakpoint.scss';
@import 'mixin.scss';
@import 'ease.scss';
@import 'font.scss';

html, body{
    margin: 0;
}
iframe{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.Lopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-color: rgba(0,0,0,0.7);

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'everett';

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart;

    * {
        box-sizing: border-box;
    }

    &.is-active{
        opacity: 1;
        visibility: visible;
        transition: opacity 0.8s $ease-out-quart, visibility 0s linear;
    }

    &--container {
        position: relative;
        display: block;
        width: 90%;
        height: 90%;
        overflow: hidden;
        background-color: #B2B4B8;
        padding: 60px;
        
        @include respond-to(sm){
            padding: 40px 40px 60px 40px;
        }
    }

    &--content {
        display: flex;
        align-content: space-between;
        flex-wrap: wrap;
        // flex-direction: column;
        
        text-align: center;
        color: white;
        height: 100%;
        font-size: 20px;

        @include respond-to(md){
            font-size: 14px;
        }
        
        &--top, &--middle, &--bottom{
            width: 100%;
        }

        &--become{
            margin-top: 15px;
            color: black;
            opacity: 0.5;
            span{
                opacity: 0.8;
            }
        }

        &--engage{
            margin-top: 50px;
            line-height: 24px;
            @include respond-to(md){
                margin-top: 30px;
            }
        }
    }

    &--logo{
        display: block;
        margin: 0 auto;
        width: 380px;
        max-width: 380px;
        height: auto;

        @include respond-to(md){
            width: 80%;
        }
    }

    &--close{
        position: absolute;
        top: 30px;
        right: 30px;
        width: 25px;
        height: 25px;

        cursor: pointer;
    }
}


.Question{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
    
    margin: 0 auto;
    width: 600px;
    height: 24px;
    position: relative;

    @include respond-to(sm){
        width: 100%;
        flex-direction: column;
    }

    a{
        color: white;
        text-decoration: none;
    }
    

    &-circle-container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-circle{
        display: block;
        height: 40px;
        width: 40px;
        border: 1px solid white;
        border-radius: 20px;
        transition: background-color 0.3s linear;

        @include respond-to(sm){
            width: 20px;
            height: 20px;
            transform: none !important;
            transition: background-color 0.3s linear, transform 0.3s $ease-out-quint;
        }
    }

    &--item{
        position: relative;
        overflow: hidden;
        cursor: pointer;
        white-space: nowrap;

        &--left{
            position: absolute;
            right: calc( 50% + 80px );

            @include respond-to(sm){
                top: -30px;
                left: 50%;
                right: auto;
                width: auto;
                transform: translateX(-50%);
            }
        }
        &--right{
            position: absolute;
            left: calc( 50% + 80px );

            @include respond-to(sm){
                top: 35px;
                left: 50%;
                right: auto;
                width: auto;
                transform: translateX(-50%);
            }
        }

        &:hover + .Question-circle-container{
            .Question-circle{
                background-color: white;
                @include respond-to(md){
                    transform: translateY(10px) scale(0.75) !important;
                }
            }
        }
        &:hover + & + .Question-circle-container{
            .Question-circle{
                background-color: white;
                @include respond-to(md){
                    transform: translateY(-10px) scale(0.75) !important;
                }
            }
        }

        &:hover{
            &:before{
            transform: translate(66.66%);
            }
        }
            
        &:before{
            content:"";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 300%;
            height: 1px;
            background: linear-gradient(to right, white 0%,white 33.32%,transparent 33.33%,transparent 66.65%,white 66.66%,white 100%);

            border-left: 100% solid red;
            transition: transform 0.5s ease-in-out;
        }
    }
}